// Pinia root state (Vuex: store/index.ts)
import { defineStore } from 'pinia';
import vuexStore from '../store';
import { IUserProfile, UserOrganizationProfile } from '../../types/IUser';
import { Store as UserStore, Actions as UserActions } from '../../modules/User';
import { Store as OrganizationConfigStore } from '../../modules/OrganizationConfig';
import * as CustomField from '../../modules/CustomField';
import { ICustomField } from '../../types/ICustomField';
import { IOrganizationConfig } from '../../types/IOrganizationConfig';
import { List as ImmList } from 'immutable';

import { useStoreTemplate } from './template';
import { setCurrency } from '../../utils/Format';

interface IAppErrors {
    action?: {
        method: () => void;
        text: string;
    };
    error: Error;
}

interface IUIStateRoot {
    _organization: UserOrganizationProfile | unknown;
    _user: IUserProfile | unknown;
    _customFields: ICustomField[] | unknown;
    _activePartitionIds: string[] | unknown;
    _organizationConfig: IOrganizationConfig | unknown;
    _appErrors: IAppErrors[];
    _isHeadOfficeUser: boolean;
}

export const useStoreRoot = defineStore('root', {
    state: (): IUIStateRoot => ({
        _organization: null,
        _user: null,
        _customFields: [],
        _activePartitionIds: [],
        _organizationConfig: null,
        _appErrors: [],
        _isHeadOfficeUser: false,
    }),
    getters: {
        organization: (state: IUIStateRoot) => state._organization as UserOrganizationProfile,
        user: (state: IUIStateRoot) => state._user as IUserProfile,
        customFields: (state: IUIStateRoot) => state._customFields as ICustomField[],
        activePartitionIds: (state: IUIStateRoot) => state._activePartitionIds as number[],
        organizationConfig: (state: IUIStateRoot) => state._organizationConfig as IOrganizationConfig,
        nativeEditorTemplates: (_) => {
            const templates = useStoreTemplate().templateList;
            return templates.filter((template) => template.url.includes('v5_'));
        },
        appErrors: (state: IUIStateRoot) => state._appErrors,
        isHeadOfficeUser: (state: IUIStateRoot) => {
            const org = state._organization as UserOrganizationProfile;
            if (!org) {
                return false;
            }
            return !org.get('partitionsets') || !org.get('partitionsets').size;
        },
    },
    actions: {
        async fetchUserOrganization({ org }): Promise<UserOrganizationProfile | undefined> {
            const response = await UserStore.session().getOrgProfile(org).first().toPromise();
            this._organization = response.first();
            // Update global organization settings
            if (!this._organization) {
                return;
            }
            const currency = (this._organization as UserOrganizationProfile).getIn(['settings', 'currency']) as string;
            if (currency) {
                setCurrency(currency);
            }
            // Update the Vuex store as well
            vuexStore.commit('SET_USER_ORGANIZATION', { organization: this._organization });
            return this._organization as UserOrganizationProfile;
        },
        async fetchUserProfile() {
            const response = await UserActions.getUserProfile().toPromise();
            this._user = response.result;
            // Update the Vuex store as well
            vuexStore.commit('SET_USER_PROFILE', { user: this._user });
            return this._user as IUserProfile;
        },
        async fetchCustomFields({ org }) {
            const response = await CustomField.Store.getItems(org, 'subscriber').first().toPromise();
            if (response) {
                this._customFields = response
                    .toArray()
                    .sort((a, b) => a.field.toUpperCase().localeCompare(b.field.toUpperCase()));
                vuexStore.commit('STORE_CUSTOMFIELDS', { customFields: ImmList(this._customFields as any) });
            }
            return this._customFields;
        },
        async fetchOrganizationConfig({ org }) {
            this._organizationConfig = await OrganizationConfigStore.getConfig(org).first().toPromise();
            return this._organizationConfig;
        },
        fetchActivePartitionIds({ org }) {
            let userActivePartitions = [];
            const userPartitionSets = UserStore.getUserDetails(org).partitionSets;
            const userActivePartitionIds = JSON.parse(UserStore.getPreference('partitions/activePartitionIds') ?? '{}');
            if (userActivePartitionIds.organizationId === org.get('organization_id')) {
                userActivePartitions = userActivePartitionIds.partitionIds;
            }
            this._activePartitionIds = userActivePartitions.filter((s) => userPartitionSets.indexOf(s) != -1);
            vuexStore.commit('SET_ACTIVE_PARTITION_IDS', { activePartitionIds: this._activePartitionIds });
            return this._activePartitionIds;
        },
        setActivePartitionIds({ activePartitionIds }) {
            this._activePartitionIds = activePartitionIds;
            vuexStore.commit({
                type: 'SET_ACTIVE_PARTITION_IDS',
                value: this._activePartitionIds,
            });
        },
        addAppError({ error, action }) {
            this._appErrors.push({ error, action });
        },
        reset() {
            this.$reset();
        },
    },
});
